



















































































































































































import { Component, Ref, Vue } from 'vue-property-decorator';
import PaymentsModule from '@/store/modules/Payments/module';
import ProgramsModule from '@/store/modules/Programs/module';
import SessionsModule from '@/store/modules/Sessions/module';
import BouncingPreloader from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import AddEditProgram from '../AddEditProgram.vue';
import SessionCard from './SessionCard.vue';
import APP_UTILITIES from '@/utilities/commonFunctions';
import APP_CONST from '@/constants/AppConst';
import { ScreenText } from '@/lang/ScreenText';
import AddEditSession from './AddEditSession.vue';
import { AutoEnrollmentFilter, FilterDropdownOption, FilterOption, GetSessionsRequest, Session, SortOption, SortOptionWithDisplayText } from '@/Model/sessions/types';
import SearchBar from '@/commoncomponents/SearchBar.vue';
import DropdownList from '@/ui-components/dropdownListBx/DropdownList.vue';
import { DropdownListItem, DropdownListOptions } from '@/ui-components/dropdownListBx/types';
import EmptyStateContainer from '@/commoncomponents/EmptyStateContainer.vue';
import PaginationComponent from '@/commoncomponents/paginationcomponent/PaginationComponent.vue';
import { SortDirection } from '@/Model/shared/types';
import { Location } from 'vue-router';
import { PaymentPlan } from '@/Model/payments/types';

@Component({
  components: {
    AddEditProgram,
    BouncingPreloader,
    SessionCard,
    AddEditSession,
    SearchBar,
    DropdownList,
    EmptyStateContainer,
    PaginationComponent
  }
})
export default class Sessions extends Vue {
  readonly screenText = new ScreenText();
  readonly programsModule = ProgramsModule;
  readonly sessionsModule = SessionsModule;
  private staffCustomerPortalEnabled = false;
  private paymentPlanFlagEnabled = false;
  private currentUserRole: number = +(APP_UTILITIES.getCookie('highest_role') || APP_CONST.ZERO);
  private allowedRolesEditProgram: Array<number> = [
    APP_CONST.ROLES.BX_SUPER_ADMIN,
    APP_CONST.ROLES.BX_ADMIN,
    APP_CONST.ROLES.ACCOUNT_ADMIN
  ];
  private allowedRolesAddSession: Array<number> = [
    ...this.allowedRolesEditProgram,
    APP_CONST.ROLES.PROGRAM_ADMIN
  ];
  @Ref('pageControl') pageControl!: PaginationComponent;


  canEditProgram = this.allowedRolesEditProgram.some(r => r === this.currentUserRole); // TODO (REFACTOR): This will need to be reworked when role checks are centralized
  canAddSession = this.allowedRolesAddSession.some(r => r === this.currentUserRole); // TODO (REFACTOR): This will need to be reworked when role checks are centralized
  isAddEditProgramVisible = false;
  isAddEditSessionVisible = false;
  isEditMode = false;
  editSessionData: Session | null = null;

  filterOptions: Array<FilterDropdownOption> = [
    { filterOption: FilterOption.All, displayText: this.screenText.getScreenText('ALL') },
    { filterOption: FilterOption.AutoEnrollmentOn, displayText: this.screenText.getScreenText('SESSION_AUTO_ENROLLMENT_ON_FILTER') },
    { filterOption: FilterOption.AutoEnrollmentOff, displayText: this.screenText.getScreenText('SESSION_AUTO_ENROLLMENT_OFF_FILTER') },
  ];
  searchFilterSelected: FilterDropdownOption = this.filterOptions[0];

  sortOptions: Array<SortOptionWithDisplayText> = [
    {
      sortOption: SortOption.StartDateAsc,
      displayText: `${this.screenText.getScreenText('START_DATES')} (${this.screenText.getScreenText('ASCENDING')})`,
    },
    {
      sortOption: SortOption.StartDateDesc,
      displayText: `${this.screenText.getScreenText('START_DATES')} (${this.screenText.getScreenText('DESCENDING')})`,
    },
    {
      sortOption: SortOption.SessionNameAsc,
      displayText: `${this.screenText.getScreenText('SESSION_ADD_SESSION_NAME')} (${this.screenText.getScreenText('A-Z')})`,
    },
    {
      sortOption: SortOption.SessionNameDesc,
      displayText: `${this.screenText.getScreenText('SESSION_ADD_SESSION_NAME')} (${this.screenText.getScreenText('Z-A')})`,
    },
    {
      sortOption: SortOption.EnrolledScholarsAsc,
      displayText: `${this.screenText.getScreenText('SESSION_CARD_ENROLLED_COUNT_TEXT')} ${this.screenText.getScreenText('LABEL_SCHOLARS')} (${this.screenText.getScreenText('ASCENDING')})`,
    },
    {
      sortOption: SortOption.EnrolledScholarsDesc,
      displayText: `${this.screenText.getScreenText('SESSION_CARD_ENROLLED_COUNT_TEXT')} ${this.screenText.getScreenText('LABEL_SCHOLARS')} (${this.screenText.getScreenText('DESCENDING')})`,
    },
  ];
  sortOptionSelected: SortOptionWithDisplayText = this.sortOptions[0];

  sessionRequest: GetSessionsRequest = {
    programId: 0,
    page: 1,
    pageItemCount: 10,
    sortDir: SortDirection.Undefined,
    sessionAutoEnrollmentFilter: AutoEnrollmentFilter.All
  };

  accountPaymentPlan: PaymentPlan | null = null;

  get backButtonText(): string {
    return this.screenText.getScreenText('BTN_BACK');
  }

  get emptyListCreateTitle(): string {
    return this.canAddSession ? this.screenText.getScreenText('SESSIONS_CREATE_SESSION_TITLE') : '';
  }

  get emptyListCreateSubtitle(): string {
    return this.screenText.getScreenText('SESSIONS_CREATE_SESSION_SUBTITLE');
  }

  get addSessionButtonText(): string {
    return this.screenText.getScreenText('SESSIONS_ADD_SESSION_BUTTON_TEXT');
  }

  get searchBarPlaceholder(): string {
    return this.screenText.getScreenText('SESSION_SEARCH_PLACEHOLDER');
  }

  get shouldShowContent(): boolean {
    return !!this.programsModule.programData
      && !this.programsModule.isInitialFetchingProgramData
      && !this.programsModule.didFetchProgramDataFail
      && !this.sessionsModule.didFetchSessionsFail;
  }

  get programDateRange(): string {
    const programData = this.programsModule.programData;
    if (programData) {
      const startDate = APP_UTILITIES.formatDate(programData.programDto.startDate, false, true);
      const endDate = APP_UTILITIES.formatDate(programData.programDto.endDate, false, true);
      return `${startDate} - ${endDate}`;
    }
    return '';
  }

  get programDetails() {
    return this.programsModule.programData && this.programsModule.programData.programDto;
  }

  get filterDropdownListOptions(): DropdownListOptions {
    const dropdownList: Array<DropdownListItem> = this.filterOptions.map((filterOption) => ({
      id: filterOption.filterOption,
      value: filterOption.displayText
    }));
    return {
      id: this.filterOptions[0].filterOption,
      singleSelect: true,
      showSelectLabel: false,
      value: this.filterOptions[0].displayText,
      dropdownList,
    };
  }

  get sortDropdownListOptions(): DropdownListOptions {
    const dropdownList: Array<DropdownListItem> = this.sortOptions.map((sortOption) => ({
      id: sortOption.sortOption,
      value: sortOption.displayText
    }));
    return {
      id: this.sortOptions[0].sortOption,
      singleSelect: true,
      showSelectLabel: false,
      value: this.sortOptions[0].displayText,
      dropdownList,
    };
  }

  get sessionConfigurationRouteTo(): Location {
    return {
      name: APP_CONST.APP_ROUTES.ACCOUNT_PROGRAM_SESSION_CONFIGURATION.name,
    };
  }

  get accountPaymentPlanEnabled(): boolean {
    return this.accountPaymentPlan !== null && this.accountPaymentPlan.isActive;
  }

  async created() {
    this.fetchTotalSessionListForDisplay();

    this.staffCustomerPortalEnabled = await APP_UTILITIES.getFeatureFlag(APP_CONST.FEATURE_KEYS.staffCustomerPortal);
    this.paymentPlanFlagEnabled = await APP_UTILITIES.getFeatureFlag(APP_CONST.FEATURE_KEYS.paymentPlans);

    if (this.paymentPlanFlagEnabled) {
      await PaymentsModule.fetchPaymentPlan();
      this.accountPaymentPlan = PaymentsModule.paymentPlan;
    }
  }

  private fetchProgramAndSessionData(programId: number) {
    this.programsModule.fetchProgramData(programId);

    this.sessionRequest.programId = programId;
    this.fetchSessionData();
  }

  private fetchSessionData() {
    this.setRequestSortParameters();
    this.setRequestAutoEnrollmentFilterParameters();
    this.sessionsModule.fetchSessionsForProgram(this.sessionRequest);
  }

  private setRequestSortParameters() {
    if (this.sortOptionSelected.sortOption === SortOption.EnrolledScholarsAsc) {
      this.sessionRequest.sortField = 'EnrollmentCount';
      this.sessionRequest.sortDir = SortDirection.Asc;
    } else if (this.sortOptionSelected.sortOption === SortOption.EnrolledScholarsDesc) {
      this.sessionRequest.sortField = 'EnrollmentCount';
      this.sessionRequest.sortDir = SortDirection.Desc;
    } else if (this.sortOptionSelected.sortOption === SortOption.SessionNameAsc) {
      this.sessionRequest.sortField = 'SessionName';
      this.sessionRequest.sortDir = SortDirection.Asc;
    } else if (this.sortOptionSelected.sortOption === SortOption.SessionNameDesc) {
      this.sessionRequest.sortField = 'SessionName';
      this.sessionRequest.sortDir = SortDirection.Desc;
    } else if (this.sortOptionSelected.sortOption === SortOption.StartDateAsc) {
      this.sessionRequest.sortField = 'StartDateTime';
      this.sessionRequest.sortDir = SortDirection.Asc;
    } else if (this.sortOptionSelected.sortOption === SortOption.StartDateDesc) {
      this.sessionRequest.sortField = 'StartDateTime';
      this.sessionRequest.sortDir = SortDirection.Desc;
    }
  }

  private setRequestAutoEnrollmentFilterParameters() {
    if (this.searchFilterSelected.filterOption === FilterOption.All) {
      this.sessionRequest.sessionAutoEnrollmentFilter = AutoEnrollmentFilter.All;
    } else if (this.searchFilterSelected.filterOption === FilterOption.AutoEnrollmentOn) {
      this.sessionRequest.sessionAutoEnrollmentFilter = AutoEnrollmentFilter.On;
    } else {
      this.sessionRequest.sessionAutoEnrollmentFilter = AutoEnrollmentFilter.Off;
    }
  }

  addSession() {
    // confirm edit mode is false when adding
    this.isEditMode = false;
    this.editSessionData = null;
    this.isAddEditSessionVisible = true;
  }

  editSession(e: Session) {
    this.editSessionData = e;
    this.isEditMode = true;
    this.isAddEditSessionVisible = true;
  }

  searchSessions(searchText: string) {
    this.sessionRequest.search = searchText;

    this.resetToFirstPage();
    this.fetchSessionData();
  }

  editProgram() {
    this.isAddEditProgramVisible = true;
  }

  onSelectFilterModifier(modifierSelected: DropdownListItem) {
    this.searchFilterSelected = {
      filterOption: modifierSelected.id,
      displayText: modifierSelected.value
    };

    this.resetToFirstPage();
    this.fetchSessionData();
  }

  onSelectSortModifier(modifierSelected: DropdownListItem) {
    this.sortOptionSelected = {
      sortOption: modifierSelected.id,
      displayText: modifierSelected.value
    };

    this.resetToFirstPage();
    this.fetchSessionData();
  }

  onSessionUpdate() {
    this.isEditMode = false;
    this.editSessionData = null;

    (this.$refs.searchBarRef as any).clearSearchText();

    if (this.sessionRequest.page && this.pageControl) {
      this.pageControl.pageChange(this.sessionRequest.page - 1);
    }
    this.clearSearch();
  }

  fetchTotalSessionListForDisplay() {
    const programId = APP_UTILITIES.getCookie('program_id'); // TODO (REFACTOR): This is how the app currently stores/passes the ID to the next page to fetch data -- not ideal, but keeping for now
    this.sessionsModule.CLEAR_SESSIONS();
    if (programId) {
      const programIdInt = Number.parseInt(programId);
      this.fetchProgramAndSessionData(programIdInt);
    }
  }

  onProgramUpdate(programId: number) {
    this.resetToFirstPage();
    this.fetchProgramAndSessionData(programId);
  }

  goBackToProgramsList() {
    this.$router.push(`${APP_CONST.APP_ROUTES.ACCOUNT_PROGRAMS.path}`)
      .catch((e) => console.error('Error navigating:', e));
  }

  clearSearch() {
    this.sessionRequest.search = '';

    this.resetToFirstPage();
    this.fetchSessionData();
  }

  getDataForPage(page: number) {
    const didPageActuallyChange = page !== this.sessionRequest.page;
    if (didPageActuallyChange) {
      this.sessionRequest.page = page;
      this.fetchSessionData();
    }
  }

  resetToFirstPage() {
    this.sessionRequest.page = 1;
    if (this.pageControl) {
      this.pageControl.pageChange(this.sessionRequest.page - 1);
    }
  }
}

