
































































































































































































































import { ScreenText } from "@/lang/ScreenText";
import { DayAbbreviation } from "@/Model/selectedDays/types";
import { Session, SessionCardPromoCodeListItem } from "@/Model/sessions/types";
import APP_UTILITIES from "@/utilities/commonFunctions";
import { Component, Prop, Vue } from "vue-property-decorator";
import ContextMenu from "@/commoncomponents/contextMenuComponents/ContextMenu.vue";
import ContextMenuItem from "@/commoncomponents/contextMenuComponents/ContextMenuItem.vue";
import {
  CouponDuration,
  DiscountType,
  AmountUnitType,
  EarlyRegistrationRule,
  MultiParticipantRule,
  PriceResponse,
  PromoCode,
  SessionBillingCycle,
  PaymentPlan,
  PaymentPlanInterval,
  PaymentPlanConfiguration,
} from "@/Model/payments/types";
import {
  findSessionPrice,
  getSessionBillingCycleScreenTextMap,
} from "@/services/sessions/helpers";
import { formatCurrency } from "@/services/common/formatters";
import { getFormattedFeeDisplayValue } from "@/services/payments/helpers";
import { Status } from "@/Model/shared/types";
import { Discount, TimeRangeType } from "@/Model/payments/types";
import APP_CONST from "@/constants/AppConst";
import paymentsModule from "@/store/modules/Payments/module";
import {
  isAfter,
  isBefore,
  differenceInMonths,
  differenceInDays,
  differenceInWeeks,
  subDays,
  subWeeks,
  subMonths,
} from "date-fns";

@Component({
  components: {
    ContextMenu,
    ContextMenuItem,
  },
})
export default class SessionCard extends Vue {
  @Prop({ type: Object, required: true }) readonly session!: Session;
  @Prop({ type: Boolean, required: true }) readonly accountPaymentPlanEnabled!: boolean;
  @Prop({ type: Boolean, required: true }) readonly paymentPlanFlagEnabled!: boolean;
  readonly screenText = new ScreenText();
  private readonly sessionBillingCycleScreenTextMap: Map<
    SessionBillingCycle,
    string
  > = getSessionBillingCycleScreenTextMap(this.screenText);
  isAutomaticDiscountEnabled = false;
  paymentsModule = paymentsModule;
  discountSession: Discount[] = [];
  currentPaymentPlan: PaymentPlan | null = this.paymentsModule.paymentPlan;

  emitEdit() {
    this.$emit("editSession");
  }

  async created() {
    const automaticDiscountFlag = await APP_UTILITIES.getFeatureFlag(
      APP_CONST.FEATURE_KEYS.automaticDiscounts
    );

    this.isAutomaticDiscountEnabled = automaticDiscountFlag;
    await this.paymentsModule.fetchAutomaticDiscountsByAccount();

    //only the active discounts will be taken into account on this page
    this.discountSession = paymentsModule.discountListReference.filter(
      (discount) => discount.isActive
    );
  }

  get daysAbbreviations(): Array<DayAbbreviation> {
    return APP_UTILITIES.getDaysAbbreviations(true);
  }

  get enrollmentBadgeText(): string {
    return `${this.session.enrollmentCount}${
      this.session.scholarCapacity ? "/" + this.session.scholarCapacity : ""
    } ${this.screenText.getScreenText("SESSION_CARD_ENROLLED_COUNT_TEXT")}`;
  }

  /**
   * Gets the discount label for the current session.
   *
   * The label includes information about early registration discounts and
   * multi-participant discounts, if available.
   *
   * @returns {string} The formatted discount label.
   */
  get discountsLabel(): string {
    let earlyRegistrationLabel = "";
    let multiParticipantLabel = "";

    this.discountSession.forEach((discount) => {
      if (discount.discountTypeId === DiscountType.EARLY_REGISTRATION) {
        const discountRule = (discount.rules as [EarlyRegistrationRule])[0];
        const currentDate = new Date();
        const sessionStartDate = new Date(this.session.startDateTime);

        // The following will be a boolean that indicates if the first date is before the second date
        const isDiscountDateBeforeCurrentDate = isBefore(
          currentDate,
          sessionStartDate
        );

        if (isDiscountDateBeforeCurrentDate) {
          const dateFnsFunctionCases = {
            [TimeRangeType.MONTHS]: differenceInMonths,
            [TimeRangeType.DAYS]: differenceInDays,
            [TimeRangeType.WEEKS]: differenceInWeeks,
          };

          // Date left value is the later date (session start date), date right value is the earlier date (current date)
          const discountRuleDateDistance = dateFnsFunctionCases[
            discountRule.timeRangeType
          ](sessionStartDate, currentDate);

          // Since the current date is before the session start date, discountRuleDateDistance value will be negative
          const isDiscountInRange =
            Math.abs(discountRuleDateDistance) >= discountRule.timeRangeUnit;

          if (isDiscountInRange) {
            // Format early registration discount label to match format from design
            const discountAmount = APP_UTILITIES.addSymbol(
              discountRule.amount,
              discount.amountUnitType === AmountUnitType.FIXED_AMOUNT
            );
            const discountDate =
              APP_UTILITIES.earlyRegistrationDateLabel(discountRule);
            earlyRegistrationLabel = `${
              discount.name
            }: (Rule) ${discountAmount} off if registering ${
              discountRule.timeRangeUnit
            } ${discountDate} before session start date (expires ${
              this.session.startDateTime.split(" ")[0]
            })`;
          }
        }
      } else if (discount.discountTypeId === DiscountType.MULTI_PARTICIPANT) {
        // Format multi participant discount label to match format from design
        const discountRule = discount.rules as MultiParticipantRule[];
        multiParticipantLabel = `${discount.name}: `;

        multiParticipantLabel += discountRule
          .map((rule, index) => {
            const discountAmount = APP_UTILITIES.addSymbol(
              rule.amount,
              discount.amountUnitType === AmountUnitType.FIXED_AMOUNT
            );
            const discountParticipants = rule.participants;
            const discountParticipantsText =
              discountParticipants === 1 ? "participant" : "participants";
            const discountParticipantsLabel = `${discountParticipants} ${discountParticipantsText}`;
            return `(Rule ${
              index + 1
            }) ${discountAmount} off of ${discountParticipantsLabel}`;
          })
          .join(" | ");
      }
    });

    // devider is used to separate early registration and multi participant discounts
    // so we need to conditionally add the separator based on if both discounts are present
    const label = `${earlyRegistrationLabel}${
      earlyRegistrationLabel.length && multiParticipantLabel.length ? " | " : ""
    }${multiParticipantLabel}`;

    return label;
  }

  /**
   * Checks if the discount session data is ready.
   *
   * @returns {boolean} True if the discount session data is being fetched, otherwise false.
   */
  get computedIsAutomaticDiscountFetchInProgress(): boolean {
    return paymentsModule.isFetchingAutomaticDiscounts;
  }

  get sessionScheduleTitle(): string {
    return this.screenText.getScreenText("SESSION_CARD_SCHEDULE_SECTION_TITLE");
  }

  get enrollmentTitle(): string {
    return this.screenText.getScreenText(
      "SESSION_CARD_ENROLLMENT_SECTION_TITLE"
    );
  }

  get paymentSettingsTitle(): string {
    return this.screenText.getScreenText(
      "SESSION_CARD_PAYMENT_SETTINGS_SECTION_TITLE"
    );
  }

  get datesText(): string {
    return this.screenText.getScreenText("SESSION_CARD_DATES_TEXT");
  }

  get startDate(): string {
    return APP_UTILITIES.getFullDate(this.session.startDateTime);
  }

  get endDate(): string {
    return APP_UTILITIES.getFullDate(this.session.endDateTime);
  }

  get daysText(): string {
    return this.screenText.getScreenText("SESSION_CARD_DAYS_TEXT");
  }

  get labelActiveDiscountText(): string {
    return this.screenText.getScreenText("LABEL_DISCOUNT_ACTIVE");
  }

  get days(): string {
    const days: Array<string> = [];
    for (const dayAbbr of this.daysAbbreviations) {
      if (this.session.selectedDays.includes(dayAbbr.day)) {
        days.push(dayAbbr.abbreviation);
      }
    }
    return days.join(", ");
  }

  get hoursText(): string {
    return this.screenText.getScreenText("SESSION_CARD_HOURS_TEXT");
  }

  get startTime(): string {
    return APP_UTILITIES.convert12hrFormateTimeFromDate(
      this.session.startDateTime
    );
  }

  get endTime(): string {
    return APP_UTILITIES.convert12hrFormateTimeFromDate(
      this.session.endDateTime
    );
  }

  get ageRangeText(): string {
    return this.screenText.getScreenText("SESSION_CARD_AGE_RANGE_TEXT");
  }

  get autoEnrollmentText(): string {
    return this.screenText.getScreenText("SESSION_CARD_AUTO_ENROLLMENT_TEXT");
  }

  get autoEnrollmentOnOffText(): string {
    return this.session.autoEnroll
      ? this.screenText.getScreenText(
          "SESSION_CARD_AUTO_ENROLLMENT_ENABLED_TEXT"
        )
      : this.screenText.getScreenText(
          "SESSION_CARD_AUTO_ENROLLMENT_DISABLED_TEXT"
        );
  }

  get sessionPrice(): PriceResponse | undefined {
    return findSessionPrice(this.session);
  }

  get sessionPriceAmountFormatted(): string {
    if (this.sessionPrice) {
      if (this.isSessionPaymentOneTime) {
        return formatCurrency(this.sessionPrice.price);
      } else {
        return `${formatCurrency(
          this.sessionPrice.price
        )} ${this.screenText.getScreenText("SESSION_CARD_TOTAL_TEXT")}`;
      }
    }
    return "";
  }

  get isSessionFree(): boolean {
    if (this.sessionPrice) {
      return this.sessionPrice.price === 0;
    }
    return true;
  }

  get isSessionPaymentOneTime(): boolean {
    if (this.sessionPrice) {
      return this.sessionPrice.billingCycle === SessionBillingCycle.OneTime;
    }
    return false;
  }

  get sessionPriceLabel(): string {
    if (this.sessionPrice && this.paymentPlanFlagEnabled) {
      return this.screenText.getScreenText("SESSION_CARD_PAYMENT_SETTINGS_PRICE_LABEL_TEXT");
    } else if (this.sessionPrice) {
      return this.isSessionPaymentOneTime
        ? this.screenText.getScreenText("SESSION_CARD_PAYMENT_SETTINGS_ONE_TIME_PAYMENT_TEXT")
        : this.screenText.getScreenText("SESSION_CARD_PAYMENT_SETTINGS_RECURRING_PAYMENT_TEXT");
    }
    return "";
  }

  get sessionIntervalLabel(): string {
    return this.screenText.getScreenText(
      "SESSION_CARD_PAYMENT_SETTINGS_INTERVAL_TEXT"
    );
  }

  get sessionIntervalText(): string {
    if (this.sessionPrice) {
      return (
        this.sessionBillingCycleScreenTextMap.get(
          this.sessionPrice.billingCycle
        ) || ""
      );
    }
    return "";
  }

  /**
   * Gets the session payment plan label.
   *
   * @returns {string} The session payment plan label.
   */
  get sessionPaymentPlanLabel(): string {
    return this.screenText.getScreenText("SESSION_CARD_PAYMENT_SETTINGS_PAYMENT_PLAN_TEXT");
  }

  /**
   * Checks if the payment plan is active and should be applied to the session.
   *
   * @returns {boolean} True if the payment plan is enabled and the session is not excluded from payment plans, otherwise false.
   */
  get isPaymentPlanEnabledForSession(): boolean {
    return (this.accountPaymentPlanEnabled && !this.isSessionExcludedFromPaymentPlans)
  }

  /**
   * Checks if the session is excluded from payment plans.
   *
   * @returns {boolean} True if the session is excluded from payment plans based on individual session settings, minimum order amount, or disable payments date, otherwise false.
   */
  get isSessionExcludedFromPaymentPlans(): boolean {
    let intervalCount: number = 0;
    let intervalType: PaymentPlanInterval | null = null;
    let dateToCheck: Date | null = null;

    if (this.currentPaymentPlan && this.accountPaymentPlanEnabled) {
      intervalCount = this.currentPaymentPlan.disablePaymentsCount || 0;
      intervalType = this.currentPaymentPlan.disablePaymentsInterval || null;
    }

    const intervalFunctionMap = {
      [PaymentPlanInterval.DAYS]: subDays,
      [PaymentPlanInterval.WEEKS]: subWeeks,
      [PaymentPlanInterval.MONTHS]: subMonths,
    } as const;

    if (intervalCount > 0 && intervalType !== null) {
      const calculateDate = intervalFunctionMap[intervalType] || (() => new Date(this.session.startDateTime));
      dateToCheck = calculateDate(new Date(this.session.startDateTime), intervalCount);
    }

    const individualSessionExcluded: boolean = this.session.isPaymentPlanEnabled === false;

    const excludedByMinimumAmount: boolean = Array.isArray(this.session.prices) && this.session.prices.some((price: PriceResponse) => {
      const minimumOrderAmount: number = this.currentPaymentPlan && this.currentPaymentPlan.minimumOrderAmount !== null
        ? this.currentPaymentPlan.minimumOrderAmount
        : 0;

      return price.price < minimumOrderAmount;
    });

    const excludedByDisablePaymentsDate: boolean = dateToCheck !== null && isAfter(Date.now(), dateToCheck);

    return individualSessionExcluded || excludedByMinimumAmount || excludedByDisablePaymentsDate;
  }

  /**
   * Gets the payment plan tooltip descriptions.
   *
   * @returns {Array<string>} The account's payment plan description(s) formatted for the tooltip.
   */
  get sessionPaymentPlanTooltipDescriptions(): Array<string> {
    let descriptions: Array<string> = [];
    let interval: string = "";

    if (this.currentPaymentPlan) {
      const configurations: Array<PaymentPlanConfiguration> = this.currentPaymentPlan.paymentPlanConfigurations;

      descriptions = configurations.map((configuration: PaymentPlanConfiguration, index: number) => {
        switch (configuration.paymentInterval) {
          case 1:
            interval = "week";
            break;
          case 2:
            interval = "other week";
            break;
          case 3:
            interval = "month";
            break;
        }

        return `Pay in ${configuration.paymentIntervalCount}, every ${interval}`;
      });

      return descriptions;
    }

    return descriptions;
  }

  /**
   * Checks if the session has payment plan exceptions.
   *
   * @returns {boolean} True if the session has payment plan exceptions based on minimum order amount or disable payments date, otherwise false.
   */
  get sessionPaymentPlanHasExceptions(): boolean {
    if (this.currentPaymentPlan) {
      return this.currentPaymentPlan.minimumOrderAmount !== null || this.currentPaymentPlan.disablePaymentsCount !== null;
    }

    return false;
  }

  /**
   * Gets the session payment plan tooltip exceptions.
   *
   * @returns {Array<string>} The account's payment plan exception(s) formattedfor the tooltip.
   */
  get sessionPaymentPlanTooltipExceptions(): Array<string> {
    let tooltipExceptions: Array<string> = [];
    if (this.currentPaymentPlan) {
      if (this.currentPaymentPlan.minimumOrderAmount) {
        tooltipExceptions.push(`Orders under $${this.currentPaymentPlan.minimumOrderAmount}.`)
      }

      if (this.currentPaymentPlan.disablePaymentsCount) {
        let interval: string = this.currentPaymentPlan.disablePaymentsCount > 1
          ? PaymentPlanInterval[this.currentPaymentPlan.disablePaymentsInterval].toLowerCase()
          : PaymentPlanInterval[this.currentPaymentPlan.disablePaymentsInterval].toLowerCase().slice(0, -1);

        tooltipExceptions.push(`${this.currentPaymentPlan.disablePaymentsCount} ${interval} before sessions starts.`)
      }
    }
    return tooltipExceptions;
  }

  get sessionTaxRateLabel(): string {
    return this.screenText.getScreenText(
      "SESSION_CARD_PAYMENT_SETTINGS_TAX_RATE_TEXT"
    );
  }

  get sessionTaxRateText(): string {
    return this.session.taxRate
      ? `${this.session.taxRate.displayName} ${this.screenText.getScreenText(
          "SESSION_CARD_PAYMENT_SETTINGS_TAX_RATE_JOINING_TEXT"
        )} ${this.session.taxRate.percentage}%`
      : this.screenText.getScreenText(
          "SESSION_CARD_PAYMENT_SETTINGS_NOT_APPLICABLE_TEXT"
        );
  }

  get sessionPromoCodesLabel(): string {
    return this.screenText.getScreenText(
      "SESSION_CARD_PAYMENT_SETTINGS_PROMO_CODES_TEXT"
    );
  }

  private mapPromoCodeItems(
    promoCode: PromoCode
  ): SessionCardPromoCodeListItem {
    let amountOrPercentOff = "";
    if (promoCode.coupon.amountOff) {
      amountOrPercentOff = formatCurrency(promoCode.coupon.amountOff);
    } else if (promoCode.coupon.percentOff) {
      amountOrPercentOff = `${promoCode.coupon.percentOff}%`;
    }
    let displayText = `${
      promoCode.code
    }: ${amountOrPercentOff} ${this.screenText.getScreenText(
      "SESSION_CARD_PAYMENT_SETTINGS_PROMO_CODES_DISCOUNT_OFF_TEXT"
    )}`;
    if (promoCode.coupon.duration === CouponDuration.Once) {
      displayText += ` ${this.screenText.getScreenText(
        "SESSION_CARD_PAYMENT_SETTINGS_PROMO_CODES_DURATION_ONCE_TEXT"
      )}`;
    } else if (promoCode.coupon.duration === CouponDuration.Repeating) {
      const forText = this.screenText.getScreenText(
        "SESSION_CARD_PAYMENT_SETTINGS_PROMO_CODES_DURATION_FOR_TEXT"
      );
      const monthsText = this.screenText.getScreenText(
        "SESSION_CARD_PAYMENT_SETTINGS_PROMO_CODES_DURATION_MONTHS_TEXT"
      );
      displayText += ` ${forText} ${promoCode.coupon.durationInMonths} ${monthsText}`;
    } else if (promoCode.coupon.duration === CouponDuration.Forever) {
      displayText += ` ${this.screenText.getScreenText(
        "SESSION_CARD_PAYMENT_SETTINGS_PROMO_CODES_DURATION_FOREVER_TEXT"
      )}`;
    }
    if (promoCode.expiresAt) {
      const date = APP_UTILITIES.dateTimeStringAsUTCDateTime(
        promoCode.expiresAt
      );
      const expiresText = this.screenText.getScreenText(
        "SESSION_CARD_PAYMENT_SETTINGS_PROMO_CODES_REDEEM_BY_TEXT"
      );
      displayText += ` (${expiresText} ${APP_UTILITIES.getFullDate(date)})`;
    }
    return {
      id: promoCode.id,
      displayText,
    };
  }

  get sessionPromoCodesList(): Array<SessionCardPromoCodeListItem> {
    return this.session.promoCodes && this.session.promoCodes.length
      ? this.session.promoCodes.map(this.mapPromoCodeItems)
      : [
          {
            id: "promo-code-NA",
            displayText: this.screenText.getScreenText(
              "SESSION_CARD_PAYMENT_SETTINGS_NOT_APPLICABLE_TEXT"
            ),
          },
        ];
  }

  get sessionCustomFeeLabel(): string {
    return this.screenText.getScreenText(
      "SESSION_CARD_PAYMENT_SETTINGS_FEE_LABEL_TEXT"
    );
  }

  get sessionCustomFeeText(): string {
    // As of right now there is only one Fee for a Payment Account, which is shown for all paid Sessions.
    // Defaulting to use the 1st one as it is unknown how multiple Fees may be handled/displayed in the future.
    const customFee = this.session.customFees[0];
    let text = "";
    if (customFee && customFee.status === Status.Active) {
      text = `${customFee.name}: ${getFormattedFeeDisplayValue(customFee)}`;
      if (customFee.taxRate) {
        const taxRateLabelText =
          this.screenText.getScreenText("LABEL_TAX_RATE");
        const joiningText = this.screenText.getScreenText(
          "SESSION_CARD_PAYMENT_SETTINGS_TAX_RATE_JOINING_TEXT"
        );
        const taxPercentageText = `${customFee.taxRate.percentage}%`;
        text += ` (${taxRateLabelText}: ${customFee.taxRate.displayName} ${joiningText} ${taxPercentageText})`;
      }
    }
    return text;
  }
}
